import React from 'react';

const CheckBox = props => {
    const { categories } = props;
    return (
        <div>
            <input 
                className="mr-2"
                type="checkbox" 
                name="categories" 
                value={props.value}
                checked={categories.includes(props.value) ? true : false}
                onChange={props.handleCategoryChange}
            />
            {props.title}
        </div>
    );
}

class CategoryCheckboxes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.items.map((item,index) => {
                        return (
                            <CheckBox title={item.title} value={item.slug} key={index} handleCategoryChange={this.props.handleCategoryChange} categories={this.props.categories}/>
                        );
                    })
                }
            </div>
        );
    }
}

export default CategoryCheckboxes;