import React from 'react';

const CheckBox = props => {

    if(props.default === props.value) {
        return (
            <div>
                <input 
                    type="checkbox" 
                    name="site" 
                    disabled
                    checked
                    className="mr-2"
                />
                {props.title}
            </div>
        );
    }else {
        return (
            <div>
                <input 
                    className="mr-2"
                    type="checkbox" 
                    name="site" 
                    value={props.value}
                    checked={props.sites.includes(props.value) ? true : false}
                    onChange={props.handleSitesChange}
                />
                {props.title}
            </div>
        );
    }
}

class SiteCheckboxes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.items.map((item,index) => {
                        return (
                            <CheckBox title={item.title} value={item.value} key={index} handleSitesChange={this.props.handleSitesChange} default={this.props.default} sites={this.props.sites}/>
                        );
                    })
                }
            </div>
        );
    }
}

export default SiteCheckboxes;