import React from 'react'
import Navigation from './router/navigation'
// import firebase from './firebase.config';
import './styles/tailwind.css'
// import { createStore } from 'redux';
import { Provider } from 'react-redux'
import rootReducer from './reducers/index'
import { UPDATE_USER, REMOVE_USER } from './actions/constants'
import config from './config'
import Amplify from 'aws-amplify'
import { Auth } from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'
import store from './reducers/store'
import { SET_USER } from './actions/constants'

// const store = createStore(store);

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
})

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            user: null,
        }
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
            .then((user) => {
                console.log(user)
                store.dispatch({
                    type: SET_USER,
                    payload: {
                        user,
                    },
                })
            })
            .catch((err) => console.log(err))
    }

    render() {
        return (
            <Provider store={store}>
                <Navigation />
            </Provider>
        )
    }
}

export default withAuthenticator(App)
