import React from 'react'
import Layout from '../../components/Layout'
import { withRouter } from 'react-router-dom'
import {
    getCategories,
    deleteCategory,
    createCategory,
} from '../../api/categories'
import config from '../../config'

class Categories extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            site: props.match.params.id,
            categories: [],
            isLoading: true,
            title: '',
        }
    }

    componentDidMount() {
        // gets initial categories
        getCategories({
            site: this.state.site,
        }).then((categories) => {
            this.setState({
                categories: categories.data,
                isLoading: false,
            })
        })
    }

    handleCreate = () => {
        // new cateogry object
        let newCategory = {
            title: this.state.title,
            site: this.state.site,
        }

        // creates new category and then update local state
        createCategory(newCategory).then((category) => {
            let { categories } = this.state
            categories.push(category.data)
            this.setState({
                title: '',
                categories,
            })

            alert('New category created successfully')
        })
    }

    handleDelete = (category) => {
        // message to ask user
        const message = `Are you sure you want to delete "${category.title}" category? This won't affect any posts or menu navigation links. This cannot be reversed.`

        // ask user to confirm before deleting
        if (window.confirm(message)) {
            deleteCategory(category).then(() => {
                let { categories } = this.state
                let updated_categories = categories.filter(
                    (item) => item.id !== category.id
                )
                this.setState({
                    categories: updated_categories,
                })
            })
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value,
        })
    }

    render() {
        const { categories, isLoading } = this.state
        return (
            <Layout>
                <div>
                    <div className="mt-12 mb-2 flex justify-between items-center">
                        <div>
                            <div className="text-2xl font-semibold">
                                Create New Category
                            </div>
                            <div className="text-gray-700 text-sm">
                                Create a new post category
                            </div>
                        </div>
                        <div className="flex items-center flex-row"></div>
                    </div>
                    <div>
                        <div className="flex flex-row items-center">
                            <div>
                                <input
                                    name="title"
                                    value={this.state.title}
                                    placeholder="Title"
                                    onChange={this.handleChange}
                                    className="border-2 border-gray-300 focus:border-blue-500 px-3 py-2 rounded-lg outline-none"
                                />
                            </div>
                            <div>
                                <div
                                    onClick={
                                        this.state.title !== ''
                                            ? () =>
                                                  this.handleCreate()
                                            : null
                                    }
                                    className={`${
                                        this.state.title === ''
                                            ? 'bg-gray-400 cursor-not-allowed opacity-50 border-2 border-gray-400'
                                            : 'bg-green-500 hover:bg-green-600 text-white cursor-pointer border-2 border-green-500 hover:border-green-600'
                                    } ml-2 px-3 py-2 rounded-lg`}
                                >
                                    Create Cateogry
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-12 mb-2 flex justify-between items-center">
                        <div>
                            <div className="text-2xl font-semibold">
                                Post Categories
                            </div>
                            <div className="text-gray-700 text-sm">
                                View and manage current post
                                categories
                            </div>
                        </div>
                        <div className="flex items-center flex-row"></div>
                    </div>
                    <div className="flex flex-wrap rounded overflow-hidden shadow mb-8 bg-white">
                        <div className="w-full p-4 flex flex-row justify-between font-bold border-b-2 border-gray-100">
                            <div className="w-1/2">Title</div>
                            <div className="w-1/2 flex justify-end">
                                Action
                            </div>
                        </div>
                        {isLoading && <div>Loading...</div>}
                        {config.staticPostCategories.map(
                            (location, index) => (
                                <div
                                    className="w-full p-4 hover:bg-orange-100 flex flex-row items-center justify-between"
                                    key={index}
                                >
                                    <div className="w-1/2 truncate">
                                        {location.title}
                                    </div>
                                    <div className="w-1/2 flex justify-end truncate">
                                        <div
                                            onClick={null}
                                            className="ml-2 border-2 border-gray-500 text-gray-500 px-3 py-1 rounded cursor-not-allowed opacity-25"
                                        >
                                            <svg
                                                className="w-6 h-6 py-1"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M3 6L5 6 21 6M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        {!isLoading &&
                            categories.map((category) => (
                                <div
                                    className="w-full p-4 hover:bg-orange-100 flex flex-row items-center justify-between"
                                    key={category.id}
                                >
                                    <div className="w-1/2 truncate">
                                        {category.title}
                                    </div>
                                    <div className="w-1/2 flex justify-end truncate">
                                        <div
                                            onClick={() =>
                                                this.handleDelete(
                                                    category
                                                )
                                            }
                                            className="ml-2 border-2 border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-3 py-1 rounded cursor-pointer"
                                        >
                                            <svg
                                                className="w-6 h-6 py-1"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M3 6L5 6 21 6M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withRouter(Categories)
