import React from 'react';
import PropTypes from 'prop-types';

const Loader = props => (
    <div>
        <span className="saving">
            {props.title}<span>.</span><span>.</span><span>.</span>
        </span>
    </div>
)

Loader.propTypes = {
    title: PropTypes.string,
}

Loader.defaultProps = {
    
}

export default Loader;