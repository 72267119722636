import React from 'react'
import Layout from '../../components/Layout'
import {
    getPage,
    updatePage,
    deletePage,
    set_currently_editing,
} from '../../api/pages'
import { getLocations, getAd } from '../../api/locations'
import { getCategories } from '../../api/categories'
import config from '../../config'
import SiteCheckboxes from '../../components/SiteCheckboxes'
import DateTimePicker from 'react-datetime-picker'
import BodyEditor from '../../components/BodyEditor'
import { Redirect } from 'react-router-dom'
import buildHtml from '../../utils/buildHtml'
import Editor from '../../components/Editor'
import slugify from '../../utils/slugify'
import { connect } from 'react-redux'
import CurrentlyEditing from '../../components/CurrentlyEditing'

class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            site: props.match.params.id,
            pageId: props.match.params.pageId,
            page: {},
            adLocations: [],
            isLoading: true,
            editingCode: false,
            imagePicker: false,
            toPages: false,
            isSaving: false,
            successfulSave: false,
            autoSave: false,
        }
    }

    componentDidMount() {
        //this.startAutoSaveCountdown()

        getPage(this.state.pageId).then((page) => {
            this.setState({
                isLoading: false,
                page: {
                    ...page.data,
                    editor:
                        page.data.editor === undefined
                            ? 'v1'
                            : page.data.editor,
                },
            })

            // if the post isn't being updated, update with current user values
            if (page.data.editing_by === null) {
                console.log('THIS SHOULD FIRE')
                set_currently_editing(
                    page.data.id,
                    this.props.user.attributes.email
                )
                // this.setEditingByStatus(
                //     this.props.user.attributes.email
                // )
            }
        })

        getLocations({ site: this.state.site }).then(
            (adLocations) => {
                let allLocations = adLocations.data.concat(
                    config.staticAdLocations
                )

                this.setState({
                    adLocations: allLocations,
                })
            }
        )
    }

    // startAutoSaveCountdown = () => {
    //     console.log('starting autosave countdown........')
    //     // 15 minutes is 900000
    //     setTimeout(() => {
    //         this.setState(
    //             {
    //                 autoSave: false,
    //             },
    //             () => {
    //                 console.log('AUTOSAVE IS OFF ==>')
    //             }
    //         )
    //     }, 900000)
    // }

    componentWillUnmount() {
        // user leaves post, update editing status
        // if user deleted page, don't try and update
        if (this.state.toPages === false) {
            set_currently_editing(this.state.page.id, null).then(
                () => {
                    console.log('editing updated for page...')
                }
            )
        }
    }

    // setEditingByStatus = (value) => {
    //     console.log('setting editing status')

    //     let updatedPage = {
    //         ...this.state.page,
    //         editing_by: value,
    //     }

    //     this.setState({
    //         page: updatedPage,
    //     })

    //     updatePage(updatedPage).then((data) => {
    //         console.log(`editing by is updated`)
    //     })
    // }

    takeOverEditing = () => {
        console.log('TAKING OVER EDITING ===>')

        let updatedPage = this.state.page

        updatedPage.editing_by = this.props.user.attributes.email

        this.setState({
            updatedPage,
        })
    }

    closeImagePicker = () => {
        this.setState({
            imagePicker: false,
        })
    }

    handleSave = () => {
        this.setState({
            isSaving: true,
        })

        let updatedPage = {
            ...this.state.page,
        }

        updatePage(updatedPage).then((page) => {
            console.log('page has been updated')

            this.setState({
                isSaving: false,
                successfulSave: true,
            })

            setTimeout(() => {
                this.setState({
                    successfulSave: false,
                })
            }, 2500)

            // if (autosave) {
            //     this.setState({
            //         isSaving: false,
            //     })
            // } else {
            //     this.setState({
            //         isSaving: false,
            //         successfulSave: true,
            //     })
            //     setTimeout(() => {
            //         this.setState({
            //             successfulSave: false,
            //         })
            //     }, 2500)
            // }
        })
    }

    handleRadioChange = (event) => {
        let { page } = this.state
        const { name, value } = event.target
        page[name] = JSON.parse(value)
        this.setState({
            page,
        })
    }

    handleChange = (event) => {
        let { page } = this.state
        const { name, value } = event.target
        page[name] = value
        this.setState({
            page,
        })
    }

    handleDateChange = (date) => {
        let { page } = this.state
        page.published = date
        this.setState({
            page,
        })
    }

    handleBodyChange = (html) => {
        let { page } = this.state
        page.body = html
        this.setState({
            page,
        })
    }

    handleCodeChange = (event) => {
        let code = event.target.value
        let { page } = this.state
        page.body = code
        this.setState({
            page,
        })
    }

    handleCodeEditorChange = () => {
        this.setState({
            editingCode: !this.state.editingCode,
        })
    }

    // handleImageSelect = (url) => {
    //     this.setState({
    //         imagePicker: false,
    //     })
    //     document.execCommand('insertImage', false, url) // Send the command to the browser
    // }

    // handleMouseDownForImage = (event) => {
    //     event.preventDefault()

    //     if (document.queryCommandEnabled('insertImage')) {
    //         this.setState({
    //             imagePicker: true,
    //         })
    //     } else {
    //         alert(
    //             'Place your cursor where you want the image and then try again'
    //         )
    //     }
    // }

    handleDelete = () => {
        const confirmMessage =
            'Are you sure you want to delete this page? This is irreversible'

        const approveDelete = window.confirm(confirmMessage)

        if (approveDelete) {
            deletePage(
                this.state.page,
                this.props.user.attributes.email
            ).then(() => {
                console.log('page deleted')
                this.setState({
                    toPages: true,
                })
            })
        }
    }

    getSite = () => {
        let site = config.sites.filter(
            (site) => site.value === this.state.site
        )
        return site[0].url
    }

    autoSave = (blocks) => {
        // update state when editor v2 is changed from child
        const { page } = this.state
        page.blocks = JSON.stringify(blocks)
        page.body = buildHtml(blocks)
        this.setState({ page })
    }

    // autoSave = (blocks) => {
    //     // clear timeout if one is set
    //     clearTimeout(autosavetimer)

    //     // update state
    //     const { page } = this.state
    //     page.blocks = JSON.stringify(blocks)
    //     page.body = buildHtml(blocks)
    //     this.setState({ page })

    //     // start the autosaveer if page is in pending status
    //     // if (page.status === 'pending') {
    //     //     autosavetimer = setTimeout(() => {
    //     //         console.log('autosaving...')
    //     //         this.handleSave(true)
    //     //     }, 500)
    //     // }
    // }

    // pickImage = () => {
    //     console.log('select image')
    // }

    render() {
        const {
            isLoading,
            page,
            adLocations,
            toPages,
            site,
            isSaving,
            successfulSave,
        } = this.state

        if (toPages) {
            return <Redirect to={`/dashboard/${site}/pages`} />
        }

        // If the post is currently being edited, but by someone other than the
        // editingBy email.
        if (
            page.editing_by &&
            page.editing_by !== this.props.user.attributes.email
        ) {
            return (
                <CurrentlyEditing
                    email={page.editing_by}
                    takeOverEditing={this.takeOverEditing}
                    backRoute="pages"
                />
            )
        }

        return (
            <Layout>
                <div>
                    {isLoading && <div>Loading post...</div>}
                    {!isLoading && page && (
                        <div>
                            <div className="mb-1">
                                <input
                                    name="title"
                                    value={page.title}
                                    onChange={this.handleChange}
                                    className="text-xl w-full border-gray-300 border-2 px-3 py-1 outline-none focus:border-blue-500 rounded"
                                    placeholder="title"
                                />
                            </div>
                            <div className="mb-4">
                                <span className="text-gray-700 uppercase mr-2">
                                    URL:
                                </span>
                                <a
                                    className="text-blue-600 hover:underline"
                                    target="_blank"
                                    href={`${this.getSite()}/pages/${slugify(
                                        page.title
                                    )}`}
                                >
                                    {this.getSite()}/pages/
                                    {slugify(page.title)}
                                </a>
                            </div>
                            <div className="flex mb-24">
                                <div className="w-2/3 pr-4">
                                    {page.editor === 'v2' ? (
                                        <div className="bg-white rounded shadow-md p-4">
                                            <Editor
                                                pickImage={
                                                    this.pickImage
                                                }
                                                blocks={page.blocks}
                                                autoSave={
                                                    this.autoSave
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="sticky top-0 bg-white rounded-l-t overflow-hidden border-b-2 border-gray-200">
                                                <EditButton cmd="Italic" />
                                                <EditButton cmd="Bold" />
                                                <LinkButton />
                                                {/* <button
                                                        className="text-gray-900 py-3 px-4 hover:bg-gray-400 focus:outline-none"
                                                        onMouseDown={(
                                                            event
                                                        ) =>
                                                            this.handleMouseDownForImage(
                                                                event
                                                            )
                                                        }
                                                    >
                                                        Image
                                                    </button> */}
                                                <button
                                                    className="text-gray-900 py-3 px-4 hover:bg-gray-400 focus:outline-none"
                                                    onClick={
                                                        this
                                                            .handleCodeEditorChange
                                                    }
                                                >
                                                    {this.state
                                                        .editingCode && (
                                                        <span>
                                                            Text
                                                            Editor
                                                        </span>
                                                    )}
                                                    {!this.state
                                                        .editingCode && (
                                                        <span>
                                                            Code
                                                            Editor
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                            <div className="bg-white rounded shadow">
                                                {!this.state
                                                    .editingCode && (
                                                    <BodyEditor
                                                        handleBodyChange={
                                                            this
                                                                .handleBodyChange
                                                        }
                                                        value={
                                                            page.body
                                                        }
                                                        className="focus:outline-none leading-relaxed p-4 merriweather"
                                                    />
                                                )}
                                                {this.state
                                                    .editingCode && (
                                                    <div className="mb-12">
                                                        <textarea
                                                            className="w-full h-auto p-4 shadow-lg bg-gray-800 text-white font-mono focus:outline-none"
                                                            rows="40"
                                                            value={
                                                                page.body
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleCodeChange
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="w-1/3 ">
                                    {successfulSave && (
                                        <div className="w-full bg-green-500 text-white font-mono mb-4 rounded p-4">
                                            Update Successful!
                                        </div>
                                    )}
                                    <div className="flex flex-col justify-between bg-white rounded shadow-md overflow-hidden p-4">
                                        <div className="mb-4">
                                            <div
                                                onClick={
                                                    this.handleSave
                                                }
                                                className={`border-2 border-green-500 bg-green-500 hover:bg-green-600 hover:border-green-600 text-white px-3 py-1 rounded cursor-pointer block text-center`}
                                            >
                                                {isSaving
                                                    ? 'Saving...'
                                                    : 'Save'}
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="text-sm uppercase text-gray-700">
                                                Status
                                            </div>
                                            <div>
                                                Pending
                                                <input
                                                    className="ml-1 mr-2"
                                                    name="status"
                                                    type="radio"
                                                    value="pending"
                                                    checked={
                                                        page.status ===
                                                        'pending'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChange
                                                    }
                                                />
                                                Published
                                                <input
                                                    className="ml-1 mr-2"
                                                    name="status"
                                                    type="radio"
                                                    value="published"
                                                    checked={
                                                        page.status ===
                                                        'published'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChange
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="text-sm uppercase text-gray-700 mb-1">
                                                Sidebar
                                            </div>
                                            <div>
                                                <select
                                                    className="block w-full"
                                                    value={
                                                        page.ad_sidebar_location
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChange
                                                    }
                                                    name="ad_sidebar_location"
                                                >
                                                    {adLocations.map(
                                                        (
                                                            item,
                                                            index
                                                        ) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item.slug
                                                                    }
                                                                    key={
                                                                        index
                                                                    }
                                                                >
                                                                    {
                                                                        item.title
                                                                    }
                                                                </option>
                                                            )
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        <div
                                            onClick={
                                                this.handleDelete
                                            }
                                            className={`border-2 border-red-500 bg-red-500 hover:bg-red-600 hover:border-red-600 text-white px-3 py-1 rounded cursor-pointer block text-center mt-6`}
                                        >
                                            Delete Page
                                        </div>
                                    </div>
                                    {/* <div>
                                <div>
                                    Allow Comments? {JSON.stringify(post.allowComments)}
                                </div>
                                <div>
                                    No
                                    <input
                                        name="allowComments"
                                        type="radio"
                                        value={false}
                                        checked={post.allowComments ? false : true}
                                        onChange={this.handleRadioChange}
                                    />
                                    Yes
                                    <input
                                        name="allowComments"
                                        type="radio"
                                        value={true}
                                        checked={post.allowComments}
                                        onChange={this.handleRadioChange}
                                    />
                                </div>
                            </div> */}
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

const LinkButton = (props) => {
    return (
        <button
            className="text-gray-900 py-3 px-4 hover:bg-gray-400 focus:outline-none"
            onMouseDown={(evt) => {
                evt.preventDefault() // Avoids loosing focus from the editable area
                let linkUrl = window.prompt('URL')
                document.execCommand('CreateLink', false, linkUrl) // Send the command to the browser
            }}
        >
            Link
        </button>
    )
}

const EditButton = (props) => {
    return (
        <button
            className="text-gray-900 py-3 px-4 hover:bg-gray-400 focus:outline-none"
            key={props.cmd}
            onMouseDown={(evt) => {
                evt.preventDefault() // Avoids loosing focus from the editable area
                document.execCommand(props.cmd, false, props.arg) // Send the command to the browser
            }}
        >
            {props.name || props.cmd}
        </button>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}
export default connect(mapStateToProps)(Page)
