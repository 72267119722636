import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';

const ButtonType = type => {
    switch(type) {
        case 'primary':
            return 'bg-blue-500 hover:bg-blue-400 text-white';
        case 'success':
            return 'bg-green-500 hover:bg-green-400 text-white';
        case 'warning':
            return 'bg-orange-500 hover:bg-orange-400 text-white';
        case 'danger':
            return 'bg-red-500 hover:bg-red-400 text-white';
        case 'blank':
            return 'bg-white hover:bg-gray-100 text-gray-800';
        default:
            return 'bg-gray-300 hover:bg-gray-400 text-black';
    }
}

const Loading = isLoading => {
    if(isLoading) {
        return 'opacity-50 cursor-not-allowed pointer-events-none';
    }
}

const ButtonText = props => {
    if(props.isLoading) {
        return <Loader title={props.loadingText}/>
    }else {
        return props.children ? props.children : 'Default Title'
    }
}

const ButtonSize = size => {
    switch(size) {
        case 'small':
            return 'px-3 py-1 text-md';
        default:
            return 'px-6 py-2 text-md';
    }
}

const Button = props => (
  <button 
    onClick={props.onClick}
    disabled={props.isLoading}
    className={`${ButtonType(props.type)} ${Loading(props.isLoading)} ${ButtonSize(props.size)} rounded shadow outline-none focus:outline-none cursor-pointer ${props.fullWidth ? 'w-full' : 'w-auto'}`}>
    <span>
        {ButtonText(props)}
    </span>
  </button>
)

Button.propTypes = {
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    size: PropTypes.string,
    fullWidth: PropTypes.bool
}

Button.defaultProps = {
    type: 'default',
    isLoading: false,
    loadingText: 'Loading',
    onClick: () => {
        console.log('default button click');
    }
}

export default Button;