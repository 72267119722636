import React from 'react'
import Navbar from './Navbar'
import Breadcrumbs from './Breadcrumbs'

const Layout = ({ children }) => (
    <div>
        <div>
            <Navbar />
        </div>
        <div className="container mx-auto px-4">
            <div>
                <Breadcrumbs />
            </div>
            {children}
        </div>
    </div>
)

export default Layout
