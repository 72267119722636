import API, { api_key } from './api'

export const getAds = async (query) => {
    try {
        const response = await API.get('/ads', {
            params: {
                ...query,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in ads api helper',
            error: err,
        }
    }
}

export const getAd = async (id) => {
    try {
        const response = await API.get(`/ads/${id}`)

        return response.data
    } catch (err) {
        return {
            message: 'Error in ads api helper',
            error: err,
        }
    }
}

export const createAd = async (ad) => {
    try {
        const response = await API.post(
            `/ads`,
            {
                title: ad.title,
                location: ad.location,
                site: ad.site,
                code: ad.code,
                type: ad.type,
                order: ad.order,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in categories api helper',
            error: err,
        }
    }
}

export const updateAd = async (ad) => {
    try {
        const response = await API.put(
            `/ads/${ad.id}`,
            {
                code: ad.code,
                location: ad.location,
                order: ad.order,
                site: ad.site,
                status: ad.status,
                title: ad.title,
                type: ad.type,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in ads api helper',
            error: err,
        }
    }
}

export const deleteAd = async (ad) => {
    // delets ad by id
    try {
        const response = await API.delete(`/ads/${ad.id}`, {
            params: {
                ...api_key,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in categories api helper',
            error: err,
        }
    }
}
