import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import Layout from '../../components/Layout'
import {
    getMediaItem,
    deleteMediaItem,
    updateMediaItem,
} from '../../api/media'
import config from '../../config'

class MediaItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            site: props.match.params.id,
            mediaId: props.match.params.mediaId,
            mediaItem: {},
            isLoading: true,
            toMedia: false,
            successfulSave: false,
        }
    }

    componentDidMount() {
        getMediaItem(this.state.mediaId).then((media) => {
            this.setState({
                isLoading: false,
                mediaItem: media.data,
            })
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target
        const { mediaItem } = this.state
        mediaItem[name] = value
        this.setState({
            mediaItem,
        })
    }

    handleUpdate = () => {
        updateMediaItem(this.state.mediaItem).then((media) => {
            this.setState({
                successfulSave: true,
            })
            setTimeout(() => {
                this.setState({
                    successfulSave: false,
                })
            }, 2500)
        })
    }

    handleDelete = () => {
        const confirmMessage =
            'Are you sure you want to delete this media item? This is irreversible'

        const approveDelete = window.confirm(confirmMessage)

        if (approveDelete) {
            deleteMediaItem(this.state.mediaItem).then(() => {
                console.log('media deleted')
                this.setState({
                    toMedia: true,
                })
            })
        }
    }

    render() {
        const { mediaItem, successfulSave } = this.state

        if (this.state.toMedia) {
            return (
                <Redirect
                    to={`/dashboard/${this.state.site}/media`}
                />
            )
        }

        return (
            <Layout>
                <div>
                    <div className="mt-12 mb-2 flex justify-between items-center">
                        <div>
                            <div className="text-2xl font-semibold">
                                Media Details
                            </div>
                            <div className="text-gray-700 text-sm">
                                View, update or delete media item
                            </div>
                        </div>
                        <div className="flex items-center flex-row"></div>
                    </div>
                    <div className="w-full flex flex-col">
                        <div className="w-1/2 rounded overflow-auto shadow bg-white">
                            <img
                                src={`${mediaItem.url}`}
                                className={`bg-cover w-full`}
                            />
                        </div>
                        <div className=" p-4 rounded overflow-auto shadow bg-white mt-6">
                            <div className="mb-6">
                                <div className="font-semibold text-lg">
                                    Url
                                </div>
                                <div
                                    className="text text-gray-600"
                                    ref={this.textArea}
                                >
                                    {`${mediaItem.url}`}
                                </div>
                                <div className="mt-2">
                                    <a
                                        target="_blank"
                                        href={`${mediaItem.url}`}
                                        className="text-sm rounded-full py-1 px-2 bg-orange-500 text-orange-100 cursor-pointer hover:bg-orange-600"
                                    >
                                        Open media in new tab
                                    </a>
                                </div>
                            </div>
                            <div className="font-semibold text-lg">
                                Media Description
                            </div>
                            <div className="mb-4">
                                <input
                                    name="description"
                                    value={mediaItem.description}
                                    placeholder="Media Description"
                                    onChange={this.handleChange}
                                    className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <div
                                    onClick={this.handleUpdate}
                                    className={`border-2 border-blue-500 bg-blue-500 hover:bg-blue-600 hover:border-blue-600 inline-block text-white px-3 py-1 rounded cursor-pointer mr-2`}
                                >
                                    Update
                                </div>
                                <div
                                    onClick={this.handleDelete}
                                    className={`border-2 border-red-500 bg-red-500 hover:bg-red-600 hover:border-red-600 inline-block text-white px-3 py-1 rounded cursor-pointer `}
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                        {successfulSave && (
                            <div className="w-full bg-green-500 text-white font-mono mt-4 rounded p-4">
                                Update Successful!
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withRouter(MediaItem)
