// takes in blocks from editor.js and spits out
// html string for rss feed and other stuff

const buildHtml = blocks => {

    return blocks.map(block => {
        if (block.type === 'paragraph') {
            return `<p>${block.data.text}</p>`
        } else if (block.type === 'image') {
            return `<figure>
                        <img src="${block.data.url}" alt="${block.data.caption}" />
                        <figcaption>${block.data.caption}</figcaption>
                    </figure>`
        } else if (block.type === 'header') {
            return `<h2>${block.data.text}</h2>`
        } else if (block.type === 'raw') {
            return `<div>${block.data.html}</div>`
        } else if (block.type === 'list') {
            return `${block.data.style === 'ordered' ? '<ol>' : '<ul>'}
                        ${block.data.items.map(item => `<li>${item}</li>`).join("")}
                    ${block.data.style === 'ordered' ? '</ol>' : '</ul>'}`
        } else if (block.type === 'embed') {
            return `<figure>
                        <iframe width="100%" height="315" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen src="${block.data.embed}"></iframe>
                        <figcaption>${block.data.caption}</figcaption>
                    </figure>`
        } else if (block.type === 'center') {
            return `<p>${block.data.text}</p>`
        }
    }).join("")
}

export default buildHtml