import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../../components/Layout'
import { getMenu, updateMenu, createMenu } from '../../api/menu'
import { useDispatch, useSelector } from 'react-redux'
import {
    SET_MENU,
    ADD_MENU_ITEM,
    SET_MENU_CHANGES_FALSE,
} from '../../actions/constants'
import SortableLinksList from '../../components/SortableLinksList'

const Menu = (props) => {

    const dispatch = useDispatch()
    const menu = useSelector(state => state.menu)
    const menu_has_changes = useSelector(state => state.menu_has_changes)
    const site = props.match.params.id
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        // get sites menu
        getMenu({ site: site }).then((menu) => {
            // set menu in redux
            console.log(menu)
            dispatch({
                type: SET_MENU,
                payload: {
                    menu: menu.data[0],
                },
            })

            // update local loading state
            setIsLoading(false)
        })
    },[])

    const saveMenu = () => {
        updateMenu(menu).then(() => {
            console.log('menu updated')
            dispatch({
                type: SET_MENU_CHANGES_FALSE,
            })
        })
    }

    const newParentLink = () => {
        let newLink = {
            title: 'Untitled',
            link: '#',
            target: '_self',
            external: false,
            sublinks: [],
        }

        dispatch({
            type: ADD_MENU_ITEM,
            payload: {
                newLink,
            },
        })
    }

    const handleMenuCreate = () => {

        setIsLoading(true)

        createMenu(site).then((menu) => {
            // create menu in redux
            dispatch({
                type: SET_MENU,
                payload: {
                    menu,
                },
            })

            setIsLoading(false)
        })
    }

    return (
        <Layout>
        <div className="mb-12">
            <div className="flex justify-between items-center mb-4">
                <div className="w-4/5">
                    <div className="text-2xl font-semibold">
                        Site Menu
                    </div>
                    <div className="text-gray-700 text-sm">
                        Drag and drop to reoder menu, create
                        new links or sublinks. Press the save
                        button to permanently make changes.
                    </div>
                </div>
                <div>
                    <button
                        disabled={
                            !menu_has_changes
                        }
                        className={`px-3 py-2 rounded-lg ${
                            menu_has_changes
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-300 text-gray-600 cursor-not-allowed'
                        }`}
                        onClick={() => saveMenu()}
                    >
                        Save
                    </button>
                </div>
            </div>

            <div>
                {isLoading && <div>Loading menu...</div>}
                {/* {!isLoading &&
                    this.props.menu.empty === true && (
                        <div onClick={this.handleMenuCreate}>
                            Create new menu
                        </div>
                    )} */}
                {!isLoading &&
                    menu.links &&
                    !menu.empty && (
                        <div>
                            <div>
                                <SortableLinksList />
                                <div
                                    className="hover:bg-gray-400 cursor-pointer bg-gray-300 w-full p-4 items-center flex justify-center my-4 rounded shadow-inner"
                                    onClick={() => newParentLink()}
                                >
                                    Add New Parent Link
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    </Layout>
    )
}

export default Menu