import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import config from '../../config'
import { getPosts } from '../../api/posts'
import { getPages } from '../../api/pages'
import Moment from 'react-moment'
import Button from '../../components/ui/Button'
import { useSelector } from 'react-redux'

const Site = () => {
    const user = useSelector((state) => state.user)
    let { id: siteId } = useParams()
    const [site, setSite] = useState({})
    const [posts, setPosts] = useState([])
    const [pages, setPages] = useState([])
    const [postsLoading, setPostsLoading] = useState(true)
    const [pagesLoading, setPagesLoading] = useState(true)

    /**
     * Load initial data
     */
    useEffect(() => {
        getSite(siteId)
        fetchRecentPosts({
            sites: siteId,
            limit: 5,
        })
        fetchRecentPages({
            site: siteId,
            limit: 5,
        })
    }, [siteId])

    /**
     * Fetch posts by site and limit
     */
    const fetchRecentPosts = (query) => {
        getPosts(query).then((data) => {
            setPosts(data.data)
            setPostsLoading(false)
        })
    }

    /**
     * Fetch pages by site and limit
     */
    const fetchRecentPages = (query) => {
        getPages(query).then((data) => {
            setPages(data.data)
            setPagesLoading(false)
        })
    }

    /**
     * Sets site object state based off url param (id)
     */
    const getSite = (siteId) => {
        config.sites.find((site) => {
            if (site.value === siteId) {
                setSite(site)
            }
        })
    }

    return (
        <Layout>
            <div>
                <div className="mb-2 flex justify-between">
                    <div className="text-3xl font-semibold">
                        {site.title}
                    </div>
                    <div>
                        <a href={`${site.url}`} target="_blank">
                            <Button type="success">View Site</Button>
                        </a>
                    </div>
                </div>

                <div className="mt-12 mb-2">
                    <div className="text-2xl font-semibold">
                        Site Administration
                    </div>
                    <div className="text-gray-700 text-sm">
                        Manage your site
                    </div>
                </div>
                <div className="bg-white rounded shadow">
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-full md:w-1/4 p-4">
                            <Link
                                to={`/dashboard/${site.value}/posts`}
                                className="block border-2 border-gray-200 rounded hover:border-purple-500 cursor-pointer p-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div>Posts</div>
                                    <div>
                                        <svg
                                            className="w-8 h-8 text-gray-600"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M12 2L2 7 12 12 22 7 12 2zM2 17L12 22 22 17M2 12L12 17 22 12" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full sm:w-full md:w-1/4 p-4">
                            <Link
                                to={`/dashboard/${site.value}/pages`}
                                className="block border-2 border-gray-200 rounded hover:border-purple-500 cursor-pointer p-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div>Pages</div>
                                    <div>
                                        <svg
                                            className="w-8 h-8 text-gray-600"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
                                            <path d="M13 2L13 9 20 9" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full sm:w-full md:w-1/4 p-4">
                            <Link
                                to={`/dashboard/${site.value}/menu`}
                                className="block border-2 border-gray-200 rounded hover:border-purple-500 cursor-pointer p-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div>Site Menu</div>
                                    <div>
                                        <svg
                                            className="w-8 h-8 text-gray-600"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M3 12L21 12M3 6L21 6M3 18L21 18" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full sm:w-full md:w-1/4 p-4">
                            <Link
                                to={`/dashboard/${site.value}/categories`}
                                className="block border-2 border-gray-200 rounded hover:border-purple-500 cursor-pointer p-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div>Post Categories</div>
                                    <div>
                                        <svg
                                            className="w-8 h-8 text-gray-600"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M20.59 13.41l-7.17 7.17a2 2 0 01-2.83 0L2 12V2h10l8.59 8.59a2 2 0 010 2.82zM7 7L7.01 7" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full sm:w-full md:w-1/4 p-4">
                            <Link
                                to={`/dashboard/${site.value}/locations`}
                                className="block border-2 border-gray-200 rounded hover:border-purple-500 cursor-pointer p-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div>Ad Locations</div>
                                    <div>
                                        <svg
                                            className="w-8 h-8 text-gray-600"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                                            <circle
                                                cx="12"
                                                cy="10"
                                                r="3"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full sm:w-full md:w-1/4 p-4">
                            <Link
                                to={`/dashboard/${site.value}/ads`}
                                className="block border-2 border-gray-200 rounded hover:border-purple-500 cursor-pointer p-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div>Ads</div>
                                    <div>
                                        <svg
                                            className="w-8 h-8 text-gray-600"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M12 1L12 23M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full sm:w-full md:w-1/4 p-4">
                            <Link
                                to={`/dashboard/${site.value}/media`}
                                className="block border-2 border-gray-200 rounded hover:border-purple-500 cursor-pointer p-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div>Media</div>
                                    <div>
                                        <svg
                                            className="w-8 h-8 text-gray-600"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <rect
                                                x="3"
                                                y="3"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            />
                                            <circle
                                                cx="8.5"
                                                cy="8.5"
                                                r="1.5"
                                            />
                                            <path d="M21 15L16 10 5 21" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="mt-12 mb-2 flex justify-between items-center">
                    <div>
                        <div className="text-2xl font-semibold">
                            Recent Posts
                        </div>
                        <div className="text-gray-700 text-sm">
                            Quick view of latest posts activity
                        </div>
                    </div>
                    <div className="flex items-center flex-row">
                        <Link
                            to={`/dashboard/${site.value}/posts`}
                            className="ml-4"
                        >
                            <Button type="primary">
                                View All Posts
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-wrap rounded overflow-hidden shadow mb-8 bg-white">
                    <div className="w-full p-4 flex flex-row justify-between font-bold border-b-2 border-gray-100">
                        <div className="w-1/2">Title</div>
                        <div className="w-1/2 flex justify-end">
                            Published
                        </div>
                    </div>
                    {postsLoading && (
                        <div className="p-4 text-gray-800">
                            Loading...
                        </div>
                    )}
                    {!postsLoading &&
                        posts.map((post) => (
                            <Link
                                to={`/dashboard/${site.value}/posts/${post.id}`}
                                className={`w-full p-4 hover:bg-orange-200 cursor-pointer flex flex-row justify-between`}
                                key={post.id}
                            >
                                <div className="w-1/2 truncate flex items-center">
                                    <div>
                                        <div className="text-gray-900 font-semibold antialiased">
                                            {post.title}
                                        </div>
                                        <div className="text-xs flex flex-row">
                                            {post.featured && (
                                                <span className="text-blue-500 mr-2 uppercase">
                                                    Featured Post
                                                </span>
                                            )}
                                            {post.editing &&
                                                post.editingBy !==
                                                    user.attributes
                                                        .email && (
                                                    <span className="text-red-400 flex flex-row items-center">
                                                        CURRENTLY
                                                        BEING EDITED (
                                                        {post.editingBy.toLowerCase()}
                                                        )
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 flex justify-end truncate">
                                    <Moment format="LLLL">
                                        {post.published}
                                    </Moment>
                                </div>
                            </Link>
                        ))}
                </div>

                <div className="mt-12 mb-2 flex justify-between items-center">
                    <div>
                        <div className="text-2xl font-semibold">
                            Recent Pages
                        </div>
                        <div className="text-gray-700 text-sm leading-tight">
                            Recently edited pages and url
                        </div>
                    </div>
                    <div className="flex items-center flex-row">
                        <Link
                            to={`/dashboard/${site.value}/pages`}
                            className="ml-4"
                        >
                            <Button type="primary">
                                View All Pages
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-wrap rounded overflow-hidden shadow mb-8 bg-white">
                    <div className="w-full p-4 flex flex-row justify-between font-bold border-b-2 border-gray-100">
                        <div className="w-1/2">Title</div>
                        <div className="w-1/2 flex justify-end">
                            URL
                        </div>
                    </div>
                    {pagesLoading && <div>Loading...</div>}
                    {!pagesLoading &&
                        pages.map((page) => (
                            <Link
                                to={`/dashboard/${site.value}/pages/${page.id}`}
                                className="w-full p-4 hover:bg-orange-200 cursor-pointer flex flex-row justify-between"
                                key={page.id}
                            >
                                <div className="w-1/2 truncate flex items-center">
                                    <div>
                                        <div className="text-gray-900 font-semibold antialiased">
                                            {page.title}
                                        </div>
                                        <div className="text-xs flex flex-row">
                                            {page.editing &&
                                                page.editingBy !==
                                                    user.attributes
                                                        .email && (
                                                    <span className="text-red-400 flex flex-row items-center">
                                                        CURRENTLY
                                                        BEING EDITED (
                                                        {page.editingBy.toLowerCase()}
                                                        )
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 flex justify-end truncate">
                                    <span className="text-gray-600">
                                        {site.url}/
                                    </span>
                                    {page.slug}
                                </div>
                            </Link>
                        ))}
                </div>
            </div>
        </Layout>
    )
}
export default Site
