import React from 'react'
import ImagePickerModal from '../ImagePickerModal';
import { connect } from 'react-redux'
import store from '../../reducers/store'
import { SET_IMAGE_PICKER, SET_EDITOR_API } from '../../actions/constants';

class ImageLibrary extends React.Component {
    constructor(props) {
        super(props)

        this.api = this.props.api
        // console.log(this.props.editorAPI)
        this.state = this.getCurrentStateFromStore()

    }

    getCurrentStateFromStore() {

        return {
            imagePicker: store.getState().imagePicker,
            api: this.props,
        }
    }


    static get toolbox() {
        return {
            title: "Image",
            icon:
                '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    closeImagePicker = () => {
        this.setState({
            imagePicker: false
        })
    }

    handleImageSelect = url => {
        this.api.blocks.insert("image", {
            url: url,
        });
    }

    promptImage = () => {
        // var url = window.prompt("image url...");
        // if (url) {
        //     this.api.blocks.insert("image", {
        //         url: url,
        //     });
        // }
        if (this.props.api) {



            const editorIndex = this.state.api.api.blocks.getCurrentBlockIndex()

            store.dispatch({
                type: SET_EDITOR_API,
                payload: {
                    editorAPI: editorIndex
                }
            })

            store.dispatch({
                type: SET_IMAGE_PICKER,
                payload: {
                    imagePicker: true
                }
            })
            // this.state.api.blocks.insert("image", {
            //     url: '#',
            // });

            // this.props.api.blocks.insert("image", {
            //     url: 'https://images.unsplash.com/photo-1558981420-c532902e58b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2377&q=80',
            // });
        }

        // console.log(this.api)

        // this.state.api.blocks.insert("image", {
        //     url: '#',
        // });
    }

    test = () => {
        // return <ImagePickerModal closeImagePicker={this.closeImagePicker} handleImageSelect={this.handleImageSelect} />

    }



    render() {

        this.promptImage()

        return null
        // return <ImagePickerModal closeImagePicker={this.closeImagePicker} handleImageSelect={this.handleImageSelect} />

    }

    save(blockContent) {
        console.log("saving...");
        return {
            url: blockContent.value
        };
    }
}

const mapStateToProps = (...args) => {
    console.log('TESTTESTSETST')
    return args[1]
}

export default (ImageLibrary);
// export default connect(mapStateToProps)(ImageLibrary);
