import API, { api_key } from './api'

export const getLocations = async (query) => {
    try {
        const response = await API.get(`/locations`, {
            params: {
                ...query,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in locations api helper',
            error: err,
        }
    }
}

export const createLocation = async (location) => {
    try {
        const response = await API.post(
            `/locations`,
            {
                title: location.title,
                site: location.site,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in location api helper',
            error: err,
        }
    }
}

export const deleteLocation = async (location) => {
    try {
        const response = await API.delete(
            `/locations/${location.id}`,
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in locations api helper',
            error: err,
        }
    }
}
