import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'

// Routes
import Dashboard from '../views/Dashboard'
import Site from '../views/sites/Site'
import Posts from '../views/posts/Posts'
import Post from '../views/posts/Post'
import Pages from '../views/pages/Pages'
import Page from '../views/pages/Page'
import Categories from '../views/categories/Categories'
import Locations from '../views/locations/Locations'
import Ads from '../views/ads/Ads'
import Ad from '../views/ads/Ad'
import Menu from '../views/menu/Menu'
import Media from '../views/media/Media'
import MediaItem from '../views/media/MediaItem'
import Actions from '../views/admin/Actions'

const Navigation = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect from="/" to="/dashboard" />
                </Route>
                <Route
                    path="/dashboard"
                    exact
                    component={Dashboard}
                />
                <Route path="/admin" exact component={Actions} />
                <Route path="/dashboard/:id" exact component={Site} />
                <Route
                    path="/dashboard/:id/posts"
                    exact
                    component={Posts}
                />
                <Route
                    path="/dashboard/:id/posts/:postId"
                    exact
                    component={Post}
                />
                <Route
                    path="/dashboard/:id/pages"
                    exact
                    component={Pages}
                />
                <Route
                    path="/dashboard/:id/pages/:pageId"
                    exact
                    component={Page}
                />
                <Route
                    path="/dashboard/:id/categories"
                    exact
                    component={Categories}
                />
                <Route
                    path="/dashboard/:id/locations"
                    exact
                    component={Locations}
                />
                <Route
                    path="/dashboard/:id/ads"
                    exact
                    component={Ads}
                />
                <Route
                    path="/dashboard/:id/ads/:adId"
                    exact
                    component={Ad}
                />
                <Route
                    path="/dashboard/:id/menu"
                    exact
                    component={Menu}
                />
                <Route
                    path="/dashboard/:id/media"
                    exact
                    component={Media}
                />
                <Route
                    path="/dashboard/:id/media/:mediaId"
                    exact
                    component={MediaItem}
                />
            </Switch>
        </Router>
    )
}

export default Navigation
