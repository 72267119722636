import API, { api_key } from './api'

export const getPages = async (query) => {
    try {
        const response = await API.get(`/pages`, {
            params: {
                ...query,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in pages api helper',
            error: err,
        }
    }
}

export const getPage = async (id) => {
    try {
        const response = await API.get(`/pages/${id}`)

        return response.data
    } catch (err) {
        return {
            message: 'Error in pages api helper',
            error: err,
        }
    }
}

export const createPage = async (query) => {
    const default_page = {
        ...query,
        title: 'Untitled',
        ad_sidebar_location: 'innerPageSidebar',
    }

    try {
        const response = await API.post(
            `/pages`,
            {
                ...default_page,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in pages api helper',
            error: err,
        }
    }
}

export const updatePage = async (page) => {
    try {
        const response = await API.put(
            `/pages/${page.id}`,
            {
                title: page.title,
                body: page.body,
                status: page.status,
                site: page.site,
                ad_sidebar_location: page.ad_sidebar_location,
                ad_banner_location: page.ad_banner_location,
                blocks: page.blocks,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in pages api helper',
            error: err,
        }
    }
}

export const set_currently_editing = async (
    pageId,
    editing_by_value
) => {
    try {
        const response = await API.put(
            `/pages/${pageId}`,
            {
                editing_by: editing_by_value,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message:
                'Error in posts remove currently editing api helper',
            error: err,
        }
    }
}

export const deletePage = async (page, email) => {
    try {
        const response = await API.delete(`/pages/${page.id}`, {
            params: {
                email: email,
                ...api_key,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in pages api helper',
            error: err,
        }
    }
}
