import React from 'react'
import { useSelector } from 'react-redux'
import Button from '../components/ui/Button'
import { Link, withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'

const Navbar = (props) => {
    const user = useSelector((state) => state.user)

    const handleSignOut = () => {
        Auth.signOut()
            .then((data) => console.log(data))
            .catch((err) => console.log(err))
    }

    return (
        <div className="bg-blue-800">
            <div className="flex justify-between items-center py-2 container mx-auto mb-8 px-4 text-white">
                <div className="flex">
                    <div>
                        <Link
                            to={`/dashboard`}
                            className="hover:underline font-semibold"
                        >
                            Flight Speed
                        </Link>
                        <div className="text-xs">
                            {user.attributes
                                ? user.attributes.email
                                : null}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <div>
                        {/* <a
                            href="/admin"
                            className="mr-4 bg-blue-800 px-4 py-2 rounded hover:bg-blue-900"
                        >
                            Admin Actions
                        </a> */}
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => handleSignOut()}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Navbar)
